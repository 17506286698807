.titlebar {
  /* position: fixed; */
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 120px;
  z-index: 1;
  /* background: #F2F2F2; */
}

.qaWrapper .ant-card {
  display: inline-block;
}

.qaWrapper .ant-card-bordered {
  display: block;
}


.anna-row-align-top {
  background: transparent;
  border: none;
}

.gogogo div:first-child{
  flex: 1;
}
.contactButton {
  background: transparent;
}

.anna-card-foot {
  background: #fff;
  border-top: 1px solid #eee;
}

.anna-search_bar-action_button {
  display: inline-block;
  /* background-image: url(https://oss-open.aichan.info/imgs/%E6%8C%89%E9%94%AE%E8%BE%93%E5%85%A5%28key%20enter%29_%E7%88%B1%E7%BB%99%E7%BD%91_aigei_com.png); */
  background-size: 100% 100%;
  width: 100px;
  height: 64px;
  line-height: 64px;
  background: #1480FC;
  border-radius: 8px 8px 8px 8px;
  opacity: 0.5;
  text-align: center;
  color: #fff;
  font-size: 28px !important;
  padding-left: 0px !important;
  position: relative;
  top: -4px;
  /* left: -6px; */
}

.anna-search_bar-show_action_button {
  opacity: 1;
}
/* .anna-search_bar-action_button {
  font-size: 38px !important;
  padding-left: 40px !important;
  position: relative;
  top: -10px;
} */

.buttonClicked {
  background: rgba(107, 178, 216, 0.6);
}

.contactInPopup {
  background: rgba(107, 178, 216, 0.6);
  color: #fff;
  height: 80px;
  line-height: 80px;
  width: 250px;
  text-align: center;
  display: inline-block;
  margin: 30px auto;
  margin-left: 50%;
  transform: translateX(-50%);
}

.myAvatarText {
  font-size: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  position: relative;
  top: -20px;
  left: -10px;
}


.aiAvatarText {
  font-size: 20px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  position: relative;
  top: -20px;
  left: 0px;
}


.rightCornerBar {
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.100000);
  background-color: rgba(255,255,255,1.000000);
  border-radius: 8px;
  height: 80px;
  margin-left: 56px;
  display: flex;
  flex-direction: column;
  width: 80px;
  position: fixed;
  right: 0;
  top: 30px;
  z-index: 1;
}
.hiddenIcon {
  width: 38px;
  height: 38px;
  margin: 20px 0 0 24px;
}


.ff {
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.100000) ;
  background-color: rgba(255,255,255,1.000000);
  border-radius: 8px;
  position: absolute;
  right:-3.5rem;
  top: -4rem;
  width: 172px;
  height: 65px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transform: scale(0.5);
  /* border:solid 1px red; */
}

.rightCornerBarFullPCWrapper {
  position: fixed;
  bottom: 10vh;
  width: 100%;
  left: 0;
  height: 80px;
  z-index: 10000;

}


.rightCornerBarFullPCWrapperInner {
  position: relative;
  margin: 0 auto;
  max-width: 750px;
  height: 80px;

  
}

.rightCornerBarFullPC {
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.100000);
  background-color: rgba(255,255,255,1.000000);
  border-radius: 8px;
  /* position: absolute;
  right:-3.5rem;
  top: -4rem; */
  width: 172px;
  height: 65px;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  transform: scale(0.5);
  position: absolute;
  right: 0px;
  right: -42px;
  bottom: 0px;
  /* border:solid 1px red; */
  /* margin-left:-80px; */
  
  /* border:solid 1px red; */
}



.rightCornerBarFullPC1 {
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.100000);
  background-color: rgba(255,255,255,1.000000);
  border-radius: 8px;
  /* position: absolute;
  right:-3.5rem;
  top: -4rem; */
  width: 172px;
  height: 65px;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  transform: scale(0.5);
  position: absolute;
  right: 0px;
  right: -42px;
  bottom: 40px;
  /* border:solid 1px red; */
  /* margin-left:-80px; */
  
  /* border:solid 1px red; */
}

.serviceBar {
  width: 140px;
  height: 35px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0 0 18px;
}
.serviceIcon {
  width: 32px;
  height: 32px;
  background-image: url(https://oss-open.aichan.info/imgs/%E4%BA%BA%E5%B7%A5%E5%AE%A2%E6%9C%8D%402x.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}


.serviceIcon1 {
  width: 32px;
  height: 32px;
  background-image: url(https://oss-open.aichan.info/imgs/H5/liuyan.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}




.guessBar {
  width: 140px;
  height: 36px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 28px 0 16px 18px;
}
.guessIcon {
  width: 38px;
  height: 38px;
}
.rightCornerText {
  width: 100px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51,51,51,1.000000);
  font-size: 25px;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  line-height: 20px;
  padding-left: 4px;
  /* margin-top: 4px; */
}

.leftText {
  text-align: left;
  padding-left: 8px;
  border-radius: 0;
  width: 114px;
  padding-right: 0px;
  margin-right: 0px;
  padding-top: 4px;
}

.searchBar {
  /* border-radius: 24px; */
}

.searchBar .anna-icon {
  display: none;
}

.anna-search_bar-input-square {
  margin-right: 24px;
}

.anna-search_bar-input-synthetic {
  left: 24px;
  transform: translate(0, -50%);
}

.anna-mask {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.contentWrapperNo .ant-card-bordered {
  border: 0;
  margin: 0;
}

.contentWrapperNo .ant-card-bordered  .ant-card-head {
  padding: 0 10px
}

.contentWrapperNo .ant-card-bordered .ant-card-body {
  padding: 0 10px
}

.contentWrapperNo {
  line-height: 24px;
  word-break: break-all;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-card .ant-card-head {
  border-bottom: 0px solid #fff;
  margin: 12px;
  margin-bottom: -12px;
  text-align: left;
  margin-left: 0px;
}

:where(.css-qgg3xn).ant-card .ant-card-head {
  border-bottom: 0px solid #fff;
  margin: 12px;
  margin-bottom: -12px;
  text-align: left;
  margin-left: 0px;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-card-bordered {
  margin: 0px 12px;
}

:where(.css-qgg3xn).ant-card-bordered {
  margin: 0px 12px;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-btn {
  padding-left: 5px;
  padding-right: 5px;
}

:where(.css-qgg3xn).ant-btn {
  padding-left: 5px;
  padding-right: 5px;
}


.contentWrapperNo :where(.css-qgg3xn).ant-card-bordered {
  margin: 0px;
}

.contentWrapperNo :where(.css-dev-only-do-not-override-qgg3xn).ant-card-bordered {
  margin: -6px -10px;
}


  /* 大屏幕样式 */
  @media (min-width: 1200px) {
    html {
      font-size: 14px !important;
    }
  }

  @media (max-width: 768px) {
    .historyIntro {
      font-size: 8px;
    }
  }

  @media (min-width: 768px) {
    .historyIntro {
      font-size: 14px;
    }
  }