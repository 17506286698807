.ant-tabs {
  margin-top: 30px;
}

.ant-tabs {
  width: calc(100% - 24px);
  margin: 0 auto;
  margin-top: 45px;
  border-radius: 16px;
  overflow: hidden;
  min-height: 336px;
  background: #FFFFFF;
  /* padding-top: 10px; */
}

.charerIcon {
  width: 138px;
  height: 94px;
  position: absolute;
  right: 35px;
  bottom: 62px;
}

.charerIconPc {
  width: 138px;
  height: 94px;
  position: absolute;
  /* right:22rem; */
  right: 9rem;
  bottom: 62px;
}

.ant-tabs-tab {
  font-size: 32px;
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;
  margin-right: 0 !important;
  color: #333333 !important;
  font-weight: bold !important;
  border: none !important;
  background: #fff !important;
}

.ant-tabs-tab-active {
  color: #333333 !important;
  position: relative;
}

.ant-tabs-tab-active::after {
  content: "";
  height: 4px;
  width: 16px;
  position: absolute;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #1890ff;
  overflow: visible;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-sizing: border-box;
  border-radius: 4px;

}

.ant-tabs-tab-active-1 {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  width: 16px;
  border-radius: 4px;
  background-color: #1890ff;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-nav-list {
  width: 100%;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-card-bordered {
  border: 0px !important;
}

:where(.css-dev-only-do-not-override-qgg3xn).ant-tabs-top>.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-qgg3xn).ant-tabs-bottom>.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-qgg3xn).ant-tabs-top>div>.ant-tabs-nav::before, :where(.css-dev-only-do-not-override-qgg3xn).ant-tabs-bottom>div>.ant-tabs-nav::before {
  border-bottom: 0px !important;
}

.onlineQuestion {
  height: 90px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.onlineQuestionV2 {
  /* height: 180px; */
  min-width: 110px;
  background: #FFFFFF;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  opacity: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.onlineLeft {
  margin-left: 20px;
}

.content1 {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 16px;
}

.content1V2 {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 16px;
  margin-top: 10px;
  margin-left: -21px;
}

.content2 {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  margin-top: 6px;
}

.onlineLeftIcon {
  width: 60px;
  height: 60px;
  background: linear-gradient(142deg, #61B5F4 0%, #1480FC 100%);
  border-radius: 16px;
  opacity: 1;
  margin-right: 15px;
  margin-left: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onlineLeftIconV2 {
  width: 50px;
  height: 50px;
  background: linear-gradient(142deg, #61B5F4 0%, #1480FC 100%);
  border-radius: 16px;
  opacity: 1;
  margin-right: 15px;
  margin-left: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.onlinePanal {
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;

  position: absolute;
  width: 100%;
  top: 180px;
  padding: 0 12px;
}

.onlinePanalPC {
  display: flex;
  align-items: center;
  justify-content: space-around;

  box-sizing: border-box;

  position: absolute;
  width: 100%;
  top: 180px;
  padding: 0 12px;
}



.zixunIcon {
  width: 40px;
  height: 40px;


}

.zixunIconV2 {
  width: 29px;
  height: 31px;
}

.banliIcon {
  width: 35px;
  height: 40px;
  margin-top: 6px;
}



/* 大屏幕样式 */
@media (min-width: 1200px) {
  .indexButton, .bottomButton {
    font-size: 1.5rem;
    padding: 1.5rem;
  }
}

/* 中等屏幕样式 */
@media (min-width: 768px) and (max-width: 1199px) {
  .indexButton, .bottomButton {
    font-size: 1.2rem;
    padding: 1rem;
  }

  .buttonsWrapper, .indexBottombuttonsWrapper {
    flex-direction: row;
  }
}

/* 小屏幕样式 */
@media (max-width: 767px) {
  .indexButton, .bottomButton {
    font-size: 1rem;
    padding: 0.8rem;
  }

  .buttonsWrapper {
    flex-direction: column;
  }

  .indexButton, .bottomButton {
    margin: 0.5rem 0;
  }
}

/* 超小屏幕样式 */
@media (max-width: 480px) {
  .indexButton, .bottomButton {
    font-size: 0.8rem;
    padding: 0.6rem;
  }

  .buttonsWrapper {
    flex-direction: column;
  }

  .indexButton, .bottomButton {
    margin: 0.5rem 0;
  }
}


.footer {
  margin-top: 80px;
  font-size: 11px;
  color: #666;
}

.huangshiMention {
  margin: 40px 24px 0px;
  font-size: 14px;
  margin-bottom: -24px;
}

.huangshiMention a {
  color: #1480FC;
}

.careName {
  position: fixed;
  bottom: 40px;
  right: 0;
  /* width: 152px; */
  height: 44px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px 0px 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.careImg {
  width: 32px;
  height: 32px;
  padding-left: 12px;
}

.careImg-maoxiaodeng {
  width: 48px;
  height: 40px;
  padding-left: 12px;
}



.care-content {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  line-height: 10px;
  text-align: center;
  margin-left: 8px;

}

.care-content-maoxiaodeng {


  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  /* color: #1480FC; */
  /* color: #4CC0C1; */
  line-height: 16px;
  text-align: center;
  margin-left: 8px;
}

.careName-maoxiaodeng {
  position: fixed;
  bottom: 35px;
  right: 0;
  /* width: 152px; */
  height: 32px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px 0px 0px 12px;
  display: flex;
  align-items: center;
  justify-content: center;

}

input {
  height: 40px !important;
}

:where(.css-161f05s).ant-input-group .ant-input {
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
  height: 40px;
}