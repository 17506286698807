.btn {
  border-radius: 8px !important;
  height: 32px !important;
  line-height: 14px !important;
  background: #ecf5ff !important;
  border-color: #b3d8ff !important;
}

.file-item {
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #F3F3F3;
}

.name {
  margin-right: 10px;
  text-align: left;
  margin-left: 0px;

}