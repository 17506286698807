/* .imgScene {
    width: 100%;
    height: 500px;
  }
  
  .anna-card-content {
    border-radius: 12rpx;
    margin-top: 12rpx;
  }
  
  .thingsNetPanal{
    background: #FFFFFF;
    border-radius: 32px;
    opacity: 1;
    margin:24px;
    padding: 40px 40px 48px;
  }
  
  .netContent{
    font-size: 32px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin-bottom: 26px;
  
  
  }
  .nameCenterPanal{
    display: flex;
    align-items: center;
  
  }
  .nameContent{
    font-size: 28px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height:28px;
  margin-right:8px;
  }
  
  .zhengwuCenterImg{
    width: 28px;
    height: 25px;
  }
  
  .addressContent{
    font-size: 24px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin:16px 0;
  
  }
  
  .anna-card-content {
    padding:0 !important;
  }
  
  .phoneContent{
    font-size: 24px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #666666;
  }
  
  .workTimePanal{
    display: flex;
    justify-content: space-between;
  
  }
  
  .workContent{
    font-size: 24px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    margin:16px 0;
    width: 560px;
    
  }
   */


   .bg {
    overflow: auto;
            height: 100vh;
            position: fixed;
            top: 0;
            width: 100%;
            z-Index: 1;
            background-Color: #F8F8F8

            ;
         }

         .bgPC {
          overflow: auto;
                  height: 100vh;
                  position: fixed;
                  top: 0;
                  width: 100%;
                  z-Index: 1;
                  background-Color: #F8F8F8 ;
                  margin: 0 auto;
                  max-width: 750px;
       

               }
  
  /* .anna-card-content {
      border-radius: 12px;
      margin-top: 12px; 
      
  } */
  
  .thingsNetPanal {
    background: #FFFFFF;
    border-radius: 16px;
    opacity: 1;
    margin: 12px;
    padding: 20px 19px 16px;
    margin-top: 50px;
  }
  
  .netContent {
    font-size: 16px;
    font-family: 'PingFang SC', sans-serif;
    font-weight: 600;
    color: #333333;
    margin-bottom: 20px;
    text-align: left;
   
  }
  
  .nameCenterPanal {
    display: flex;
    align-items: center;
   
  }
  
  
  
  .nameContent {
    font-size: 14px;
    font-family: 'PingFang SC', sans-serif;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
    margin-right: 2px;
    
  
  }
  
  
  
  .addressContent {
    font-size: 12px;
    font-family: 'PingFang SC', sans-serif;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    margin: 8px 0 ;
    text-align: left;
  }
  
  .phoneContent {
    font-size: 12px;
    font-family: 'PingFang SC', sans-serif;
    font-weight: 400;
    color: #666666;
    text-align: left;
  }
  
  .workTimePanal {
    display: flex;
    justify-content: space-between;
    
  }
  
  .workContent {
    font-size: 12px;
    font-family: 'PingFang SC', sans-serif;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    margin: 8px 0;
    width: 170px;
    text-align: left;
   
  }