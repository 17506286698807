.imgScene {
  width: 100%;
  height: 500px;
}

.anna-card-content {
  border-radius: 6px;
  margin-top: 6px;
}
/* 
.ant-card-body {
  padding: 0!important;
} */

.contact-info {
  text-align: left;
}

/* 大屏幕样式 */
@media (min-width: 1200px) {
  .contact-info {
    font-size: 18px;
    font-weight: bold;
  }
}

/* 中等屏幕样式 */
@media (min-width: 768px) and (max-width: 1199px) {
  .contact-info {
    font-size: 16px;
    font-weight: bold;
    border: solid 1px red !important;
  }
}

/* 小屏幕样式 */
@media (max-width: 767px) {
  .contact-info {
    font-size: 14px;
    font-weight: normal;
    border: solid 1px red !important;
  }
}

/* 超小屏幕样式 */
@media (max-width: 480px) {
  .contact-info {
    font-size: 12px;
    font-weight: normal;
    border: solid 1px red !important;
  }
}