.headerWrapper {
  position: fixed;
  width: 100%;
  height: 40px;
  top: 0px;
  left: 0px;
  background: #fff;
  z-index: 1000;

  
}

.outHeader{

  margin: 0 auto;
  /* max-width: 750px; */
  /* min-width: 7.5rem; */
  box-sizing: border-box;
  position: relative;
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 40px;
  z-index: 1000;
}

.headerWrapperPc{
  width: 100%;
  height: 40px;

  background: #fff;
  max-width: 750px;
  /* margin: 0 auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin: 0 auto;
  position: relative;


}

.headerWrapper .headerTitle {
  line-height: 40px;
  font-size: 16px;
}

.headerWrapper .returnIcon {
  position: absolute;
  left: 10px;
  top: 10px;
}

 .returnIconP {
  position: absolute;
  left: 10px;
  top: 10px;

}