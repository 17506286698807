.view {
  background:#444;
  display: flex;
  justify-content: center;
  height: 60vh;
  padding: 50px 0;
  overflow: auto;
  margin-top: 24px;
}
.pageContainer {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px;
  width:max-content;
  max-width:100%;
}
.pageTool{
  position: absolute;
  bottom: 20px;
  background: rgb(66, 66, 66);
  color: white;
  padding: 8px 15px;
  border-radius: 15px;

}


input{
  display: inline-block;
  width: 50px;
  text-align: center;
  margin-right: 10px;
  height: 24px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}

i{
  padding: 5px;
  margin:0 5px;
}


.ant-modal-content :where(.css-dev-only-do-not-override-1kf000u).ant-btn-variant-outlined, :where(.css-dev-only-do-not-override-1kf000u).ant-btn-variant-dashed {
  display: none;
}

.ant-modal-content :where(.css-1kf000u).ant-btn-variant-outlined, :where(.css-1kf000u).ant-btn-variant-dashed {
  display: none;
}

:where(.css-1kf000u).ant-input-group .ant-input {
  height: 40px;
}

:where(.css-dev-only-do-not-override-1kf000u).ant-input-group .ant-input {
  height: 40px;
}