.inforCard{
  background: #FFFFFF;
  border-radius: 16px;
 margin: 12px;
 
}

.buttonImage {
  width: 60px;
  height: 60px;
  margin-right: 35px;
  position: relative;
  top: 10px;
}

.onlinePanal{
  display: flex;
  justify-content: space-between;

}


.infoTitle{

font-size: 16px;
font-family: PingFang SC, PingFang SC;
font-weight: 600;
color: #333333;
line-height: 16px;
margin:0 19px 24px;
padding-top:20px;
text-align: left;



}

.onlineDealPanal{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  margin:0px 8px 0px;
  padding-bottom: 8px;
  
}

.onlineContent{
  font-size: 14px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 27px;
  margin-top:4px;

}
.avangeWidth{
 width: 25%;
  /* border: solid 1px red; */
 height: 60px;
 margin-bottom: 16px;

}